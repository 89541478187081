<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>个人赛赛务管理</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 个人赛赛程 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">个人赛赛程</span>
                </el-col>
            </el-row>
            <!-- <el-row :gutter="30"> -->
            <div class="schedule-list">
                <div
                    class="schedule-item"
                    v-for="(itemData, index) in scheduleCardList"
                    v-bind:key="index"
                >
                    <gameStageBackview
                        :itemData="itemData"
                        :key="index"
                        :itemIndex="index"
                        @itemClicked="scheduleCardTap"
                    ></gameStageBackview>
                </div>
            </div>
            <!-- </el-row> -->
        </el-card>
        <!-- 个人赛赛务工具 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">个人赛赛务工具</span>
                </el-col>
            </el-row>
            <el-row :gutter="30">
                <el-col
                    style="margin-top: 30px"
                    :span="4"
                    v-for="(item, i) of racingToolList"
                    v-bind:key="i"
                >
                    <gameToolBackView
                        :itemData="item"
                        :key="i"
                        @itemClicked="racingToolClicked"
                    ></gameToolBackView>
                </el-col>
            </el-row>
        </el-card>
        <el-row :gutter="16">
            <el-col :span="12">
                <!-- 待处理事务 -->
                <el-card style="margin-top: 15px">
                    <el-row class="title_row">
                        <el-col>
                            <span class="title_class">待处理事务</span>
                        </el-col>
                    </el-row>
                    <el-row :gutter="30">
                        <el-col
                            :span="6"
                            v-for="(item, i) of processedList"
                            v-bind:key="i"
                        >
                            <gameTransactionBackview
                                :itemData="item"
                                :key="i"
                                @itemClicked="handleToPage"
                            ></gameTransactionBackview>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="12">
                <!-- 数据统计 -->
                <el-card style="margin-top: 15px">
                    <el-row class="title_row">
                        <el-col>
                            <span class="title_class">数据统计</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col
                            :span="6"
                            v-for="(item, i) of dataStatisticsList"
                            v-bind:key="i"
                        >
                            <div class="processed-list-back">
                                <div class="dataStatistics-desc">
                                    {{ item.number }}
                                </div>
                                <div class="dataStatistics-title">
                                    {{ item.title }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <!-- 数据统计 -->
        <el-tabs type="border-card" style="margin-top: 15px">
            <el-row :gutter="20">
                <el-col :span="8">
                    <div class="rank-class">
                        考量排行榜 <span class="rank-update">每天更新</span>
                    </div>
                    <div class="rankTypeback">
                        <div class="rank-type">
                            <div
                                :class="
                                    marketingRankType === 'marketing'
                                        ? 'rankTypeActive rankTypeActive1'
                                        : 'rankTypeItem'
                                "
                                @click="
                                    ranktypeClicked(
                                        'consideration',
                                        'marketing'
                                    )
                                "
                            >
                                直营
                            </div>
                            <div
                                :class="
                                    marketingRankType === 'authorization'
                                        ? 'rankTypeActive rankTypeActive1'
                                        : 'rankTypeItem'
                                "
                                @click="
                                    ranktypeClicked(
                                        'consideration',
                                        'authorization'
                                    )
                                "
                            >
                                授权
                            </div>
                        </div>
                        <el-table
                            :data="marketingRankList"
                            style="width: 100%"
                            size="small"
                        >
                            <el-table-column
                                prop="rankStr"
                                label="排名"
                                width="50"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="districtName"
                                label="赛区"
                                width="100"
                            >
                            </el-table-column>
                            <el-table-column label="考量" prop="consider">
                                <template scope="scope">
                                    <el-progress
                                        type="line"
                                        :percentage="
                                            (scope.row.consider / 10000) * 100
                                        "
                                        :format="
                                            format(scope.row, scope.column)
                                        "
                                        color="#2db8f8"
                                        :text-inside="false"
                                    />
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="rank-class">
                        缴费排行榜 <span class="rank-update">每天更新</span>
                    </div>
                    <div class="rankTypeback">
                        <div class="rank-type">
                            <div
                                :class="
                                    payRankType === 'marketing'
                                        ? 'rankTypeActive rankTypeActive2'
                                        : 'rankTypeItem'
                                "
                                @click="ranktypeClicked('pay', 'marketing')"
                            >
                                直营
                            </div>
                            <div
                                :class="
                                    payRankType === 'authorization'
                                        ? 'rankTypeActive rankTypeActive2'
                                        : 'rankTypeItem'
                                "
                                @click="ranktypeClicked('pay', 'authorization')"
                            >
                                授权
                            </div>
                        </div>
                        <el-table
                            :data="payRankRankList"
                            style="width: 100%"
                            size="small"
                        >
                            <el-table-column
                                prop="rankStr"
                                label="排名"
                                width="50"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="districtName"
                                label="赛区"
                                width="100"
                            >
                            </el-table-column>
                            <el-table-column label="金额" prop="consider">
                                <template scope="scope">
                                    <el-progress
                                        type="line"
                                        :percentage="
                                            (scope.row.consider / 10000) * 100
                                        "
                                        :format="
                                            format(scope.row, scope.column)
                                        "
                                        color="#FFA94C"
                                        :text-inside="false"
                                    >
                                    </el-progress>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="rank-class">
                        转化率排行榜 <span class="rank-update">每天更新</span>
                    </div>
                    <div class="rankTypeback">
                        <div class="rank-type">
                            <div
                                :class="
                                    conversionRankType === 'marketing'
                                        ? 'rankTypeActive rankTypeActive3'
                                        : 'rankTypeItem'
                                "
                                @click="
                                    ranktypeClicked('conversion', 'marketing')
                                "
                            >
                                直营
                            </div>
                            <div
                                :class="
                                    conversionRankType === 'authorization'
                                        ? 'rankTypeActive rankTypeActive3'
                                        : 'rankTypeItem'
                                "
                                @click="
                                    ranktypeClicked(
                                        'conversion',
                                        'authorization'
                                    )
                                "
                            >
                                授权
                            </div>
                        </div>
                        <el-table
                            :data="conversionRankList"
                            style="width: 100%"
                            size="small"
                        >
                            <el-table-column
                                prop="rankStr"
                                label="排名"
                                width="50"
                            >
                            </el-table-column>
                            <el-table-column
                                prop="districtName"
                                label="赛区"
                                width="100"
                            >
                            </el-table-column>
                            <el-table-column
                                label="初复赛转化率"
                                prop="consider"
                            >
                                <template scope="scope">
                                    <el-progress
                                        thickness="10"
                                        :percentage="scope.row.consider * 100"
                                        :format="
                                            format(scope.row, scope.column)
                                        "
                                        color="#4BCED0"
                                        :text-inside="false"
                                    />
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-col>
            </el-row>
        </el-tabs>
        <!-- 喜报制作dialog -->
        <el-dialog
            title="喜报制作"
            :visible.sync="formPoster"
            width="30%"
            @close="formPosterClose"
        >
            <el-form
                :model="uploadPosterForm"
                label-width="80px"
                :rules="uploadPosterFormRules"
                ref="uploadPosterFormRef"
            >
                <el-form-item label="赛区:" prop="districtName">
                    <el-select
                        placeholder="请选择"
                        style="width: 80%"
                        v-model="uploadPosterForm.districtName"
                        clearable
                    >
                        <el-option
                            v-for="item in dict_district"
                            :key="item.id"
                            :label="item.districtName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="学校名:" prop="schoolName">
                    <el-input
                        v-model="uploadPosterForm.schoolName"
                        autocomplete="off"
                        placeholder="请输入"
                        style="width: 80%"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="是否分年级制作："
                    prop="gradesGroup"
                    label-width="140px"
                >
                    <el-radio-group v-model="uploadPosterForm.gradesGroup">
                        <el-radio label="是"></el-radio>
                        <el-radio label="否"></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="列数:" prop="columnNum">
                    <el-select
                        v-model="uploadPosterForm.columnNum"
                        placeholder="请选择"
                        style="width: 80%"
                    >
                        <el-option label="1" value="1"></el-option>
                        <el-option label="2" value="2"></el-option>
                        <el-option label="3" value="3"></el-option>
                        <el-option label="4" value="4"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-upload
                    class="upload-demo"
                    ref="uploadPosterRef"
                    :action="this.uploadPosterForm.uploadUrl"
                    :data="{
                        schoolName: this.uploadPosterForm.schoolName,
                        columnNum: this.uploadPosterForm.columnNum,
                        gradesGroup:
                            this.uploadPosterForm.gradesGroup === '是'
                                ? 'true'
                                : 'false',
                        districtName: this.uploadPosterForm.districtName,
                    }"
                    :before-upload="beforeUpload"
                    :on-preview="uploadPosterProgress"
                    :on-success="uploadPosterSuccess"
                    :on-error="uploadPostereError"
                    :on-exceed="uploadPosterExceed"
                    :headers="uploadPosterForm.headerObj"
                    :limit="1"
                    :auto-upload="true"
                >
                    <el-button
                        style="margin-left: 10px"
                        type="primary"
                        size="small"
                        @click="downLoadUploadPoster"
                        >下载模版</el-button
                    >
                    <el-button size="small" type="primary" slot="trigger"
                        >上传文件</el-button
                    >
                    <el-button
                        style="margin-left: 10px"
                        type="success"
                        size="small"
                        @click="generateGoodnewsClicked"
                        >生成喜报</el-button
                    >
                </el-upload>
            </span>
        </el-dialog>
        <!-- 单张喜报生成 -->
        <div id="printId" class="html2canvasDivShow" ref="html2canvasRef">
            <div class="pic">
                <img src="@/assets/img/CEBSB_goodnews_bg_up.png" />
                <div class="class_name">
                    {{ lessongoodNewsSchoolName }}
                </div>
            </div>
            <div class="nameList">
                <div class="multicolumn">
                    <div
                        v-bind:style="player_nameclass"
                        v-for="(itemList, index) in finalDataList"
                        :key="index"
                    >
                        <div
                            class="multicolumn-item"
                            v-for="(item, itemIndex) in itemList"
                            :key="itemIndex"
                        >
                            <div v-if="item.gradeName" class="gradeName_class">
                                {{ item.gradeName }}
                            </div>
                            <div v-else class="userName_class">{{ item }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pic2">
                <img src="@/assets/img/CEBSB_goodnews_bg_down1.png" />
            </div>
            <div class="pic4">
                <img src="@/assets/img/CEBSB_goodnews_text.png" alt="" />
            </div>
        </div>
        <!-- 多张海报生成 -->
        <div
            v-for="(item, index) in batchFinalDataList"
            :key="index"
            :id="`batchprintImage_${index}`"
            class="html2canvasDivShow"
            :ref="'html2canvasRef_' + index"
        >
            <div class="pic">
                <img src="@/assets/img/CEBSB_goodnews_bg_up.png" />
                <div class="class_name">
                    <div>{{ lessongoodNewsSchoolName }}</div>
                </div>
            </div>
            <div class="nameList">
                <!-- 多张 -->
                <div class="firstPage">{{ item.gradeName }}</div>
                <div class="multicolumn1">
                    <div
                        class="player-name"
                        v-bind:style="player_nameclass"
                        v-for="(userItem, childIndex) in item.userName"
                        :key="childIndex"
                    >
                        {{ userItem }}
                    </div>
                </div>
            </div>
            <div class="pic2">
                <img src="@/assets/img/CEBSB_goodnews_bg_down1.png" />
            </div>
            <div class="pic4">
                <img src="@/assets/img/CEBSB_goodnews_text.png" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
import html2canvas from 'html2canvas'
import { queryDistrictPage, downLoad } from '@/http/api'
export default {
  data () {
    return {
      marketingRankType: 'marketing',
      payRankType: 'marketing',
      conversionRankType: 'marketing',
      payRankRankList: [
        {
          rankStr: 1,
          districtName: '天津赛区',
          consider: '9999'
        },
        {
          rankStr: 2,
          districtName: '天津赛区',
          consider: '9000'
        },
        {
          rankStr: 3,
          districtName: '天津赛区',
          consider: '8000'
        },
        {
          rankStr: 4,
          districtName: '天津赛区',
          consider: '7000'
        },
        {
          rankStr: 5,
          districtName: '天津赛区',
          consider: '6000'
        },
        {
          rankStr: 6,
          districtName: '天津赛区',
          consider: '5500'
        },
        {
          rankStr: 7,
          districtName: '天津赛区',
          consider: '4000'
        },
        {
          rankStr: 8,
          districtName: '天津赛区',
          consider: '2000'
        }
      ],
      marketingRankList: [
        {
          rankStr: 1,
          districtName: '天津赛区',
          consider: '10000'
        },
        {
          rankStr: 2,
          districtName: '天津赛区',
          consider: '9000'
        },
        {
          rankStr: 3,
          districtName: '天津赛区',
          consider: '8000'
        },
        {
          rankStr: 4,
          districtName: '天津赛区',
          consider: '7000'
        },
        {
          rankStr: 5,
          districtName: '天津赛区',
          consider: '6000'
        },
        {
          rankStr: 6,
          districtName: '天津赛区',
          consider: '5500'
        },
        {
          rankStr: 7,
          districtName: '天津赛区',
          consider: '4000'
        },
        {
          rankStr: 8,
          districtName: '天津赛区',
          consider: '2000'
        }
      ],
      conversionRankList: [
        {
          rankStr: 1,
          districtName: '天津赛区',
          consider: 0.92
        },
        {
          rankStr: 2,
          districtName: '天津赛区',
          consider: 0.78
        },
        {
          rankStr: 3,
          districtName: '天津赛区',
          consider: 0.65
        },
        {
          rankStr: 4,
          districtName: '天津赛区',
          consider: 0.56
        },
        {
          rankStr: 5,
          districtName: '天津赛区',
          consider: 0.41
        },
        {
          rankStr: 6,
          districtName: '天津赛区',
          consider: 0.33
        },
        {
          rankStr: 7,
          districtName: '天津赛区',
          consider: 0.24
        },
        {
          rankStr: 8,
          districtName: '天津赛区',
          consider: 0.17
        }
      ],
      goodNewsData: null,
      // 单组年级
      lessongoodNewsSchoolName: '', // 学校
      lessongoodNewsDistrictName: '', // 赛区
      finalDataList: [],
      batchFinalDataList: [],
      lessonArr: {
        oneGradeNames: '一年级',
        twoGradeNames: '二年级',
        threeGradeNames: '三年级',
        fourGradeNames: '四年级',
        fiveGradeNames: '五年级',
        sixGradeNames: '六年级',
        sevenGradeNames: '七年级',
        eightGradeNames: '八年级',
        nineGradeNames: '九年级'
      },
      player_nameclass: {
        width: '100%',
        color: '#e8c77a'
      },
      dict_district: [],
      formPoster: false,
      uploadPosterForm: {
        uploadUrl: this.$env.baseIP + 'goodNews/importExcel',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        },
        schoolName: '',
        columnNum: '',
        gradesGroup: '',
        districtName: ''
      },
      uploadPosterFormRules: {
        districtName: [{ required: true, message: '请选择赛区', trigger: 'change' }],
        schoolName: [{ required: true, message: '请输入学校名', trigger: 'blur' }],
        columnNum: [{ required: true, message: '请选择列数', trigger: 'change' }],
        gradesGroup: [{ required: true, message: '请选择是否分年级', trigger: 'change' }]
      },
      scheduleCardList: [
        {
          activityType: 1,
          id: '1',
          scheduleFlag: 'PRIMARY_SELECTION',
          scheduleName: '城市初选',
          scheduleSort: 1,
          season: '2022'
        },
        {
          activityType: 1,
          id: '2',
          scheduleFlag: 'INDIVIDUAL_FINAL',
          scheduleName: '城市复选',
          scheduleSort: 2,
          season: '2022'
        },
        {
          activityType: 1,
          id: '4',
          scheduleFlag: 'CITY_HALF',
          scheduleName: '城市半决选',
          scheduleSort: 4,
          season: '2022'
        },
        {
          activityType: 1,
          id: '5',
          scheduleFlag: 'CITY_FINALS',
          scheduleName: '城市总决选',
          scheduleSort: 5,
          season: '2022'
        },
        {
          activityType: 1,
          id: '6',
          scheduleFlag: 'WEB_FINALS',
          scheduleName: '网络总决选',
          scheduleSort: 6,
          season: '2022'
        },
        {
          activityType: 1,
          id: '19',
          scheduleFlag: 'NATIONAL_FINALS_GROUP_PROMOTED',
          scheduleName: '全国总决选(晋级赛)',
          scheduleSort: 19,
          season: '2022'
        },
        {
          activityType: 1,
          id: '7',
          scheduleFlag: 'NATIONAL_FINALS_GROUP',
          scheduleName: '全国总决选(冠亚季)',
          scheduleSort: 7,
          season: '2022'
        },
        {
          activityType: 1,
          id: '8',
          scheduleFlag: 'NATIONAL_FINALS',
          scheduleName: '全国总决选(无组别)',
          scheduleSort: 8,
          season: '2022'
        }
      ],
      racingToolList: [
        {
          rightTitle: '报名信息统计',
          rightDesc: '查看/导出赛程/赛区报名数据',
          icon: 'icon-shijuan',
          menuTag: '2',
          flag: this.$chnEngStatusCode.signup_statistics_management,
          turnUrl: '/individual_racing_tool/signup_statistics'
        },
        {
          rightTitle: '试卷管理',
          rightDesc: '可查看并审核试卷申请',
          icon: 'icon-shijuan',
          menuTag: '1',
          flag: this.$chnEngStatusCode.paper_application_management,
          // turnUrl: '/individual_racing_tool/individual_school_considerations'
          turnUrl: '/individual_racing_tool/data_statistics'
        },
        // {
        //   rightTitle: '参赛码管理',
        //   rightDesc: '生成参赛码/管理有效期',
        //   icon: 'icon-chanpinbianma',
        //   menuTag: '2',
        //   flag: this.$chnEngStatusCode.productcode_management,
        //   turnUrl: '/product_Management/productcode_management'
        // },
        {
          rightTitle: '比赛时间管理',
          rightDesc: '管理赛区/学校比赛时间',
          icon: 'icon-rili',
          menuTag: '3',
          flag: this.$chnEngStatusCode.individual_gametime_manage,
          turnUrl: '/individual_racing_tool/individual_gametime_manage'
        },
        {
          rightTitle: '线下考场管理(复)',
          rightDesc: '新增/修改线下考场场地',
          icon: 'icon-house-full',
          menuTag: '4',
          flag: this.$chnEngStatusCode.examination_room_list,
          type: 'offline',
          turnUrl: '/individual_racing_tool/examination_room_list'
        },
        {
          rightTitle: '线上考场管理(复)',
          rightDesc: '新增/修改线上考场会议号',
          icon: 'icon-diannao',
          flag: this.$chnEngStatusCode.examination_onroom_list,
          turnUrl: '/individual_racing_tool/examination_room_list',
          menuTag: '5',
          type: 'online'
        },
        {
          rightTitle: '线上考场管理(网)',
          rightDesc: '新增/修改线上考场会议号',
          icon: 'icon-diannao',
          menuTag: '7',
          flag: this.$chnEngStatusCode.network_room_list,
          turnUrl: '/individual_racing_tool/examination_network_room_list'
        },
        {
          rightTitle: '生成海报',
          rightDesc: '可生成晋级海报',
          icon: 'icon-haibaosheji',
          menuTag: '6',
          flag: this.$chnEngStatusCode.tidings_of_glad_tidings,
          turnUrl: '/individual_racing_tool/tidings_of_glad_tidings'
        },
        {
          rightTitle: '照片墙审核',
          rightDesc: '国赛照片墙审核',
          icon: 'icon-haibaosheji',
          menuTag: '7',
          flag: this.$chnEngStatusCode.photo_wall_audit,
          turnUrl: '/individual_racing_tool/photo_wall_audit'
        }
      ],
      dataStatisticsList: [
        {
          title: '初赛缴费报名',
          key: 'payForThePreliminaryCompetition',
          number: 0
        }, {
          title: '初赛参赛人数',
          key: 'thePreliminaryCompetitionNum',
          number: 0
        }, {
          title: '初赛晋级人数',
          key: 'thePreliminaryCompetitionPromotionNum',
          number: 0
        }, {
          title: '发放证书人数',
          key: 'numberOfIssuingCertificates',
          number: 0
        }, {
          title: '复赛缴费报名',
          key: 'payForTheRematch',
          number: 0
        }, {
          title: '复赛参赛人数',
          key: 'numberOfParticipantsInTheRematch',
          number: 0
        }, {
          title: '复赛晋级人数',
          key: 'theRematchCompetitionNum',
          number: 0
        }, {
          title: '初转复转化率',
          key: 'initialConversionRate',
          number: '0%'
        }
      ],
      processedList: [
        {
          icon: 'icon-xuexiao_xuexiaoxinxi',
          title: '待审核学校',
          key: 'schoolToBeAudited',
          flag: this.$chnEngStatusCode.b_school_management,
          path: '/b_data_Management/b_school_management',
          number: '0',
          menuTag: '1'
        },
        {
          icon: 'icon-shijuan',
          title: '待审核试卷',
          flag: this.$chnEngStatusCode.paper_application_management,
          key: 'examinationPaperToBeAudited',
          path: '/individual_racing_tool/individual_school_considerations',
          status: '0',
          number: '0',
          menuTag: '2'
        },
        {
          icon: 'icon-fapiaoyoujidizhi',
          title: '待寄出试卷',
          flag: this.$chnEngStatusCode.paper_application_management,
          key: 'testPaperToBeSentOut',
          path: '/individual_racing_tool/individual_school_considerations',
          status: '1',
          number: '0',
          menuTag: '3'
        },
        {
          icon: 'icon-ai49',
          title: '待收回试卷',
          flag: this.$chnEngStatusCode.paper_application_management,
          key: 'examinationPaperToBeRecalled',
          path: '/individual_racing_tool/individual_school_considerations',
          status: '3',
          number: '0',
          menuTag: '4'
        },
        {
          icon: 'icon-xuexiao_xuexiaoxinxi',
          title: '待审核场地',
          key: 'siteToBeAudited',
          number: '0',
          menuTag: '5'
        },
        {
          icon: 'icon-daifahuo',
          title: '待缴费订单',
          key: 'prepaidOrder',
          flag: this.$chnEngStatusCode.product_management,
          path: '/product_Management/product_management',
          status: '1',
          number: '0',
          menuTag: '6'
        },
        {
          icon: 'icon-fahuo2',
          title: '待发货订单',
          key: 'orderForDelivery',
          flag: this.$chnEngStatusCode.product_management,
          path: '/product_Management/product_management',
          status: '2',
          number: '0',
          menuTag: '7'
        },
        {
          icon: 'icon-tuikuanguanli',
          title: '待处理退款',
          key: 'pendingRefund',
          flag: this.$chnEngStatusCode.product_management,
          path: '/product_Management/product_management',
          status: '5',
          number: '0',
          menuTag: '8'
        }
      ],
      answerIndex: 0, // 答案下标
      userAnswerIndex: 0, // 用户答题下标
      vernierIndex: 0, // 游尺下标
      interceptLength: 1, // 截取长度
      answer: 'appale', // 答案
      userAnswer: 'appappappalappalea' // 答题
    }
  },
  watch: {
    'uploadPosterForm.gradesGroup' () {
      this.$nextTick(() => {
        this.dataProcessing()
      })
    },
    'uploadPosterForm.columnNum' () {
      this.$nextTick(() => {
        this.dataProcessing()
      })
    },
    'uploadPosterForm.districtName' () {
      this.$nextTick(() => {
        this.dataProcessing()
      })
    },
    'uploadPosterForm.schoolName' () {
      this.$nextTick(() => {
        this.dataProcessing()
      })
    }
  },
  created () {
    this.getRaceScheduleList()
  },
  methods: {
    getRaceScheduleList () {
      this.$chnEngPublicRequest.querySeasonRaceSchedule(this, { activityType: '1' }).then(data => {
        var scheduleList = data.map(item => {
          item.authorityFlag = item.scheduleFlag
          return item
        })
        this.scheduleCardList = scheduleList
      })
    },
    format (row, column) {
      return () => {
        if (column.label === '考量') {
          return row.consider
        } else if (column.label === '金额') {
          return '￥' + row.consider
        } else if (column.label === '初复赛转化率') {
          return (row.consider * 100).toFixed(2) + '%'
        }
      }
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
        this.getQueryDistrictTimePage()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 赛程点击事件
    scheduleCardTap (scheduleData) {
      // let turnUrl = ''
      // switch (scheduleData.scheduleFlag) {
      //   case 'PRIMARY_SELECTION': // 初选
      //     turnUrl = '/individual_racing_schedules/preliminary_raceManagement'
      //     break
      //   case 'INDIVIDUAL_FINAL':
      //     turnUrl = '/individual_racing_schedules/rematch_raceManagement'
      //     break
      //   default:
      //     turnUrl = ''
      //     break
      // }
      console.log('scheduleData==========', scheduleData)
      this.$router.push(
        {
          path: '/individual_racing_schedules/preliminary_raceManagement',
          query: {
            raceSchedule: scheduleData.id
          }
        }
      )
    },
    // 赛务工具点击回调
    racingToolClicked (itemData) {
      switch (itemData.flag) {
        case this.$chnEngStatusCode.paper_application_management:
          this.$router.push('/individual_racing_tool/data_statistics')
          break
        case this.$chnEngStatusCode.signup_statistics_management:
          this.$router.push('/individual_racing_tool/signup_statistics')
          break
        case '2':
          this.$router.push('/product_Management/productcode_management')
          break
        case this.$chnEngStatusCode.individual_gametime_manage:
          this.$router.push('/individual_racing_tool/individual_gametime_manage')
          break
        case this.$chnEngStatusCode.examination_room_list: case this.$chnEngStatusCode.examination_onroom_list:
          this.$router.push({
            path: '/individual_racing_tool/examination_room_list',
            query: {
              type: itemData.type
            }
          })
          break
        case this.$chnEngStatusCode.network_room_list: // 网络赛考场
          this.$router.push({
            path: '/individual_racing_tool/examination_network_room_list',
            query: {
              raceScheduleId: '6'
            }
          })
          break
        case this.$chnEngStatusCode.tidings_of_glad_tidings:
          this.$router.push('/individual_racing_tool/tidings_of_glad_tidings')
          break
        case this.$chnEngStatusCode.photo_wall_audit:
          this.$router.push('/individual_racing_tool/photo_wall_audit')
          break
        default:
          this.$router.push(itemData.turnUrl)
          break
      }
    },
    // 考量排行榜切换
    ranktypeClicked (rankType, item) {
      switch (rankType) {
        case 'consideration':
          this.marketingRankType = item
          break
        case 'pay':
          this.payRankType = item
          break
        case 'conversion':
          this.conversionRankType = item
          break
        default:
          break
      }
    },
    // 关闭喜报事件
    formPosterClose () {
      this.$refs.uploadPosterFormRef.resetFields()
      // this.$refs.uploadPosterRef.resetFields()
    },
    // 下载模版
    async downLoadUploadPoster () {
      downLoad().then((res) => {
        if (res.code !== 200) {
          return this.$message.error(res.message)
        }
        var url = res.data
        var link = document.createElement('a')
        try {
          link.href = url
        } catch (error) {
          link.href = window.URL.createObjectURL(url)
        }
        link.click()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 上传文件前的校验
    beforeUpload () {
      this.$refs.uploadPosterFormRef.validate(async valid => {
        if (!valid) {
          this.$refs.uploadPosterRef.clearFiles()
          return false
        } else {
          return true
        }
      })
    },
    // 已上传钩子
    uploadPosterProgress () {
    },
    // 上传成功钩子
    uploadPosterSuccess (res) {
      if (res.code === 200) {
        this.goodNewsData = res.data
        this.dataProcessing()
      } else {
        this.$refs.uploadPosterRef.clearFiles()
        return this.$message.warning('文件解析失败，请重新上传！')
      }
    },
    // 数据处理
    dataProcessing () {
      if (this.goodNewsData !== null) {
        this.lessongoodNewsSchoolName = this.uploadPosterForm.schoolName// 学校
        this.lessongoodNewsDistrictName = this.uploadPosterForm.districtName.replace('赛', '选')// 赛区
        this.$data.player_nameclass.width = (100 / Number(this.uploadPosterForm.columnNum)) + '%'
        if (this.uploadPosterForm.gradesGroup === '否') {
          var obj = this.goodNewsData.goodNewsDetails
          const finalList = []
          for (const v in obj) { // 将所有数据合并到一个数组中
            finalList.push({ gradeName: this.lessonArr[v] })
            finalList.push.apply(finalList, obj[v])
          }
          const twoDimensionalArray = []
          var rowsNumber = parseInt(finalList.length / Number(this.uploadPosterForm.columnNum))
          for (let index = 0; index < Number(this.uploadPosterForm.columnNum); index++) {
            if (finalList.length % Number(this.uploadPosterForm.columnNum) !== 0) {
              if (index < (finalList.length % Number(this.uploadPosterForm.columnNum))) {
                twoDimensionalArray.push(finalList.slice(index * (rowsNumber + 1), (rowsNumber + 1) * (index + 1)))
              } else {
                twoDimensionalArray.push(finalList.slice(index * (rowsNumber + 1), (index + 1) + ((index + 1) * rowsNumber)))
              }
            } else {
              twoDimensionalArray.push(finalList.slice(index * rowsNumber, (index + 1) * rowsNumber))
            }
          }
          this.finalDataList = twoDimensionalArray
        }
      }
    },
    // 生成喜报
    generateGoodnewsClicked () {
      this.$refs.uploadPosterFormRef.validate(async valid => {
        if (!valid) return
        if (this.$refs.uploadPosterRef.uploadFiles.length === 0) {
          return this.$message.warning('请选择要上传的文件')
        }
        var obj = this.goodNewsData.goodNewsDetails
        const finalList = [{ gradeName: '', userName: [] }]
        if (this.uploadPosterForm.gradesGroup === '是') {
          for (const v in obj) {
            if (obj[v].length >= Number(this.uploadPosterForm.columnNum)) {
              finalList.push({ gradeName: this.lessonArr[v], userName: obj[v] })
            } else {
              this.$curUtils.closeLoading(this)
              return this.$message({
                message: '列数不能大于单年级人数',
                type: 'warning'
              })
            }
          }
          this.batchFinalDataList = finalList
          // this.formPoster = false
          for (let index = 0; index < finalList.length; index++) {
            await this.batchprintImage('batchprintImage_' + index, index)
          }
        } else {
          this.printImage()
          // this.formPoster = false
        }
      })
    },
    handleToPage ({ path, status }) {
      if (path) {
        const query = status ? { status } : null
        this.$router.push({ path, query })
      }
    },
    printImage () {
      html2canvas(document.querySelector('#printId'), {
        height: this.$refs.html2canvasRef.offsetHeight - 150,
        scale: 4, // 处理模糊问题
        dpi: 900 // 处理模糊问题
      }).then(canvas => {
        const printUrl = canvas.toDataURL('image/png', 1)
        // 创建隐藏的可下载链接
        const eleLink = document.createElement('a')
        eleLink.href = printUrl
        eleLink.download = 'pictureName'
        document.body.appendChild(eleLink)
        eleLink.click()
        document.body.removeChild(eleLink)
        this.$curUtils.closeLoading(this)
      })
    },
    batchprintImage (batchprintId, refIndex) {
      if (batchprintId !== 'batchprintImage_0') {
        this.$nextTick(() => {
          const itemRef = this.$refs['html2canvasRef_' + refIndex]
          const box = document.getElementById(batchprintId)
          if (box) {
            html2canvas(box, {
              height: itemRef[0].offsetHeight - 150,
              scale: 4, // 处理模糊问题
              dpi: 900 // 处理模糊问题
            }).then(canvas => {
              const printUrl = canvas.toDataURL('image/png', 1)
              const eleLink = document.createElement('a')
              eleLink.href = printUrl
              eleLink.download = 'pictureName'
              document.body.appendChild(eleLink)
              eleLink.click()
              document.body.removeChild(eleLink)
            })
          }
        })
      }
    },
    // 上传失败钩子
    uploadPostereError (error) {
      this.$common.closeLoading()
      this.$message.error(error)
    },
    // 超出后动作
    uploadPosterExceed (files, fileList) {
      this.$set(fileList[0], 'raw', files[0])
      this.$set(fileList[0], 'name', files[0].name)
      // this.$refs.uploadRef.clearFiles()// 清除文件
      // this.$refs.uploadRef.handleStart(files[0])// 选择文件后的赋值方法
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-progress__text {
    font-size: 10px !important;
}
.rankTypeback {
    margin: 10px 0px;
    padding: 20px;
    // border: 1px solid rgb(219, 219, 219);
}
.rankTypeItem {
    width: 42px;
    height: 24px;
    // border-radius: 6px;
    line-height: 24px;
    font-size: 12px;
    text-align: center;
    border: 1px solid #aaa7a7;
    color: #fff;
    background-color: #aaa7a7;
    // margin-right: 10px;
    cursor: pointer;
}
.rankTypeActive {
    width: 42px;
    height: 24px;
    font-size: 12px;
    // border-radius: 6px;
    line-height: 24px;
    text-align: center;
    // margin-right: 10px;
    cursor: pointer;
    color: white;
}
.rankTypeActive1 {
    background-color: #2db8f8;
    border: 1px solid #2db8f8;
}
.rankTypeActive2 {
    background-color: #f8ae19;
    border: 1px solid #f8ae19;
}
.rankTypeActive3 {
    background-color: #4bced0;
    border: 1px solid #4bced0;
}
.rank-type {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.rank-update {
    font-size: 12px;
    color: rgb(107, 107, 107);
}
.rank-class {
    font-size: 16px;
    color: rgb(39, 39, 39);
    margin-top: 5px;
}
.html2canvasDivShow {
    position: relative;
    z-index: -1;
}

.class_promotion_img {
    width: 50%;
    margin-left: 25%;
}

.player-name {
    margin-bottom: 5px;
    font-size: 18px;
    font-family: "xique";
}

.gradeName_class {
    color: white;
    font-size: 20px;
    font-family: "xique";
}

.userName_class {
    font-size: 18px;
    font-family: "xique";
}

.multicolumn1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 60%;
    margin-left: 15%;
    /* align-self: start; */
    /* justify-content: space-evenly; */
    height: 93%;
}

.multicolumn-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    line-height: 30px;
}

.multicolumn {
    display: flex;
    flex-direction: row;
    width: 60%;
    margin-left: 15%;
    /* flex-wrap: wrap; */
    /* align-self: start; */
    justify-content: center;
    /* justify-content: space-evenly; */
    height: 93%;
}

.firstPage {
    width: 60%;
    margin-left: 15%;
    color: #fff;
    font-size: 20px;
    font-family: "xique";
    margin-bottom: 20px;
    /* margin: 20px 0; */
}

.nameList {
    width: 100%;
    min-height: 279px;
    position: relative;
    margin-top: -120px;
    text-align: center;
    color: #e8c77a;
    background: url(../../../../../assets/img/CEBSB_goodnews_bg_middle2.png);
    background-size: 100%;
    z-index: 100;
}

img {
    width: 100%;
}

.class_name {
    position: absolute;
    text-align: center;
    font-family: "xique";
    width: 60%;
    margin-left: 15%;
    color: #ffffff;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 30%;
}

.class_name img {
    height: 100%;
    width: 15%;
}

.class_name div {
    /* margin: 10px; */
    position: relative;
}

.class_school {
    color: #e8c77a;
    text-align: center;
    width: 100%;
    font-size: 26px;
    position: relative;
    margin-bottom: 10px;
}

.pic {
    position: relative;
    width: 100%;
    top: 0;
}

.pic3 {
    width: 100%;
}
.pic4 {
    position: absolute;
    right: 20px;
    top: 200px;
    width: 150px;
    height: 507px;
    z-index: 101;
}
.pic4 img {
    width: 100%;
    height: 100%;
}
.pic2 {
    width: 100%;
    position: relative;
    top: -150px;
    z-index: 99;
    background-color: #3f0000;
    /* background-color: #3f0000; */
}

.pic2 img {
  margin-top: 30px;
    width: 100%;
}

#batchprintId {
    position: relative;
    width: 560px;
    min-height: 760px;
    letter-spacing: 1px;
    background-color: #3f0000;
}

#printId {
    // position: relative;
    position: fixed;
    left: -10000px;
    top: -10000px;
    width: 560px;
    min-height: 760px;
    letter-spacing: 1px;
    background-color: #3f0000;
}

#batchprintImage_0 {
    position: relative;
    width: 560px;
    min-height: 760px;
    letter-spacing: 1px;
    background-color: #3f0000;
}

#batchprintImage_1 {
    position: relative;
    width: 560px;
    min-height: 760px;
    letter-spacing: 1px;
    background-color: #3f0000;
}

#batchprintImage_2 {
    position: relative;
    width: 560px;
    min-height: 760px;
    letter-spacing: 1px;
    background-color: #3f0000;
}

#batchprintImage_3 {
    position: relative;
    width: 560px;
    min-height: 760px;
    letter-spacing: 1px;
    background-color: #3f0000;
}

#batchprintImage_4 {
    position: relative;
    width: 560px;
    min-height: 760px;
    letter-spacing: 1px;
    background-color: #3f0000;
}

#batchprintImage_5 {
    position: relative;
    width: 560px;
    min-height: 760px;
    letter-spacing: 1px;
    background-color: #3f0000;
}

#batchprintImage_6 {
    position: relative;
    width: 560px;
    min-height: 760px;
    letter-spacing: 1px;
    background-color: #3f0000;
}

#batchprintImage_7 {
    position: relative;
    width: 560px;
    min-height: 760px;
    letter-spacing: 1px;
    background-color: #3f0000;
}

#batchprintImage_8 {
    position: relative;
    width: 560px;
    min-height: 760px;
    letter-spacing: 1px;
    background-color: #3f0000;
}

#batchprintImage_9 {
    position: relative;
    width: 560px;
    min-height: 760px;
    letter-spacing: 1px;
    background-color: #3f0000;
}

#batchprintImage_10 {
    position: relative;
    width: 560px;
    min-height: 760px;
    letter-spacing: 1px;
    background-color: #3f0000;
}

#batchprintImage_11 {
    position: relative;
    width: 560px;
    min-height: 760px;
    letter-spacing: 1px;
    background-color: #3f0000;
}

#batchprintImage_12 {
    position: relative;
    width: 560px;
    min-height: 760px;
    letter-spacing: 1px;
    background-color: #3f0000;
}
</style>
